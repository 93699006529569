import './glider/glider';

const collapsibles = document.querySelectorAll('.collapsible');
collapsibles.forEach(item => {
  item.addEventListener('click', function () {
    this.classList.toggle('collapsible--expanded');
  });
});

window.addEventListener('load', function () {
  let page = window.location.pathname;

  if (page === '/about.html' || page === '/about.php') {
    // image carousel tarlac site 1
    new Glider(document.querySelector('.glider-tarlac1'), {
      // Mobile-first defaults
      slidesToShow: 1,
      slidesToScroll: 1,
      scrollLock: true,
      dots: '.dots',
      arrows: {
        prev: '.glider-prev',
        next: '.glider-next'
      },
      responsive: [
        {
          // screens greater than >= 775px
          breakpoint: 775,
          settings: {
            // Set to `auto` and provide item width to adjust to viewport
            slidesToShow: 'auto',
            slidesToScroll: 'auto',
            itemWidth: 150,
            duration: 0.25
          }
        },
        {
          // screens greater than >= 1024px
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            itemWidth: 10,
            duration: 0.25
          }
        }
      ]
    });

    // image carousel tarlac site 2
    new Glider(document.querySelector('.glider-tarlac2'), {
      slidesToShow: 1,
      dots: '.t2dots',
      draggable: true,
      arrows: {
        prev: '.t2glider-prev',
        next: ' .t2glider-next'
      }
    });
  }
});
